/******************************
  CONTENT CONTAINER - DEFAULT
*****************************/
.content-container {
  min-height: calc(100vh - 110px);
}
.places-suggestions li {
  cursor: pointer;
}
/* .navbar-dark .navbar-nav .nav-link .active {
  color: #fa970d !important;
} */
.my-nav a:hover {
  color: #fa970d !important;
}
.my-nav .active {
  color: #fa970d !important;
}
.my-nav .btn-group a {
  color: #fff !important;
}
.my-dropdown .nav-item a:hover {
  color: #fa970d !important;
  /* background-color: #fff !important; */
}
.my-dropdown .active {
  color: #fa970d !important;
  background-color: #fff !important;
}

.accordion-button:not(.collapsed) {
  color: #fff !important;
  background-color: #fa970d !important;
}
.accordion-button:focus {
  border-color: #fff !important;
  box-shadow: none !important;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

/************
  FOOTER
**********/
.footer-container {
  position: relative;
  left: 0;
  bottom: 0;
}
.footer-1 {
  padding-top: 50px;
  padding-bottom: 60px;
  background-color: #111;
}
.footer-1 .active {
  color: #fa970d;
  text-decoration: underline;
}
.footer-1 a {
  color: #ccc;
  text-decoration: none;
}
.footer-1 a:hover {
  color: #fa970d;
  text-decoration: underline;
}
.footer-2 {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #000;
}

/*****************************
  SECTION - DEFAULT SPACING
********************/
.section-margin {
  margin-top: 50px;
  margin-bottom: 50px;
}
.section-padding {
  padding-top: 30px;
  padding-bottom: 30px;
}

/********
  LOGO
********/
.logo {
  max-width: 100px;
  max-height: 50px;
}

/**************
  BUTTON
***************/
.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
  background-color: #fa970d !important;
  /* border-color: #fa970d; */
  border: none !important;
}

/**************
  APP BADGE
***************/
.app-badge {
  max-width: 150px;
  max-height: 40px;
}

/**************
  LOGIN & SIGNUP
***************/
.auth-link {
  color: #333;
  text-decoration: none;
}

/**************
  TOP MENU
***************/
.top-menu {
  padding: 5px;
  /* margin-bottom: -10px; */
  background-color: #fa970d;
}
.top-menu a {
  color: #fff;
  text-decoration: none;
}
.top-menu a:hover {
  color: #fff;
  text-decoration: underline;
}

/*******************
  BACKGROUND COLOR
*****************/
.bg-grey {
  background-color: #f2fff2;
}

/**************
  ERROR CLASS
***************/
.error {
  position: relative;
  color: red;
  font-size: 0.7rem;
}

/**************
  GOOGLE PLACES
***************/
.position-places {
  position: absolute;
  background-color: #f4f4f4;
}

/**************
  DONATION
***************/
.donation-card a {
  color: #111;
  text-decoration: none;
}
.donation-card a:hover {
  color: #111;
  text-decoration: none;
}

.gap-bottom {
  padding-bottom: 50px;
}

/************************
 SECTION - CALCULATOR 
***********************/
.section-calculator {
  padding: 100px 10px 100px;
  background-color: #f2fff2;
}
.section-calculator-text {
  padding: 0px 30px 0px 30px;
  font-size: x-large;
}
@media screen and (min-width: 767px) {
  .section-calculator-text {
    padding: 80px 10px 0px;
    font-size: xx-large;
  }
}

/********************
 SECTION - BENEFITS
**********************/
.section-benefits {
  padding: 100px 10px 100px;
  background-color: #f2fff2;
}
.section-benefits-heading {
  padding: 0px 0px 40px;
  text-align: center;
}
.section-benefits-icon {
  color: #fa970d;
  font-size: 2em;
}
.section-benefits-img {
  padding: 0px 30px 0px 30px;
  font-size: x-large;
}
@media screen and (min-width: 767px) {
  .section-benefits-img {
    padding: 30px 10px 0px;
    font-size: xx-large;
  }
}

/*********************
 SECTION - SERVICES 
********************/
.section-services {
  padding: 100px 10px 100px;
}
.section-services-text {
  padding: 0px 0px 30px 0px;
  font-size: x-large;
  text-align: center;
}
.section-services .card {
  background-color: #fa970d;
  color: #fff;
}
.section-services .card:hover {
  background-color: #fff;
  color: #000;
}
.section-services-icon {
  font-size: 2em;
}

/**********************
  SECTION - SUBSCRIBE 
********************/
/* .section-subscribe {
  padding: 100px 10px 100px;
  background: rgba(0, 0, 0, 0.7) url(../src/images/map.jpg);
  background-size: cover;
  background-blend-mode: darken;
} */
/* .section-subscribe-col-1 {
  background: url("https://picsum.photos/500/300");
  background-size: cover;
} */
.section-subscribe-col-2 {
  padding: 50px 30px;
  color: #000;
  text-align: center;
  /* background: rgba(0, 0, 0, 0.7) url(../src/images/map.jpg);
  background-size: cover;
  background-blend-mode: darken; */
}
.section-subscribe-text h4 {
  font-size: xx-large;
}
@media screen and (min-width: 767px) {
  .section-subscribe-text {
    padding: 50px 30px;
    font-size: xx-large;
  }
}

/************************
 SECTION - DONATE 
***********************/
.section-donate {
  padding: 100px 10px 30px;
  background-color: #f2fff2;
  margin-top: 100px;
}
.section-donate-text {
  padding: 20px 0px 0px 0px;
  font-size: xx-large;
}
@media screen and (min-width: 767px) {
  .section-donate-text {
    padding: 100px 0px 0px 0px;
    font-size: xx-large;
  }
}

/****************
 SCROLL TO TOP
*************/
.floating-scroll-button {
  position: fixed;
  width: 50px;
  height: 50px;
  background-color: #fa970d;
  bottom: 40px;
  right: 20px;
  color: #fff;
  border: none;
  border-radius: 50px;
}

/*********************
 FLOATING BUTTON HELP
********************/
.floating-help-button {
  position: fixed;
  width: 50px;
  height: 50px;
  background-color: #000;
  bottom: 110px;
  right: 20px;
  color: #fff;
  border-color: #fa970d;
  border-radius: 50px;
  border-style: solid;
  text-align: center;
  padding: 8px 0px 0px 0px;
}
.floating-help-button:hover {
  color: #fa970d;
}

/*************************
  SECTION - BOOK DELIVERY 
***********************/
.section-book-delivery {
  padding: 100px 10px 100px;
  background: rgba(250, 151, 13, 1) url(../src/images/map.jpg);
  background-size: cover;
  background-blend-mode: darken;
}
/* Container needed to position the button. Adjust the width as needed */
.section-book-delivery-small-banner {
  position: relative;
  /* width: 50%; */
}
/* Make the image responsive */
.section-book-delivery-small-banner img {
  width: 100%;
  height: auto;
}
/* Style the button and place it in the middle of the container/image */
.section-book-delivery-small-banner .button {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

/************************
  LET IMAGE FILL UP DIV 
*******************/
.fill {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.fill img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}

/**************************
 SECTION - TOGGLE BUTTONS
***********************/
.section-toggle-btn {
  padding: 80px 10px 80px;
  background-color: #f2fff2;
}
.section-toggle-btn .button {
  padding: 20px 0px 20px 0px;
}

/************************
 SECTION - MOBILE APP
***********************/
.section-mobile-app {
  padding: 100px 10px 30px;
}
.section-mobile-app-text {
  padding: 20px 0px 0px 0px;
  font-size: xx-large;
}
@media screen and (min-width: 767px) {
  .section-mobile-app-text {
    padding: 60px 0px 0px 0px;
    font-size: xx-large;
  }
}

/*********************
 HOMEPAGE SLIDE
******************/
#homepageCarousel .carousel-item {
  height: 28rem;
  background: #000;
  color: #fff;
  position: relative;
}
#homepageCarousel .carousel-item .container {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 0px 30px 150px;
}
#homepageCarousel .carousel-item .slide-overlay {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  top: 0px;
  background-size: cover;
  background-position: center;
  opacity: 0.5;
}
#homepageCarousel .carousel-item .container h3 {
  font-size: 2rem;
  padding-bottom: 10px;
}
.home-department-icons {
  font-size: 2em;
}

/*********************
 SUBSCRIBE SLIDE
******************/
#subscribeCarousel .carousel-item {
  height: 28rem;
  background: #000;
  color: #fff;
  position: relative;
}
#subscribeCarousel .carousel-item .container {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 0px 30px 150px;
}
#subscribeCarousel .carousel-item .slide-overlay {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  top: 0px;
  background-size: cover;
  background-position: center;
  /* opacity: 0.5; */
}

/*********************
 DONATE PROJECTS
******************/
.donate-projects img {
  max-width: 100%;
  max-height: 200px;
}

/************
 ABOUT US
***********/
.about-1 {
  padding: 80px 0px 0px;
  margin-bottom: 50px;
}
.about-2 {
  padding: 80px 0px;
  background-color: #f4f4f4;
}
.about-3 {
  padding: 80px 0px;
}
.about-4 {
  padding: 80px 0px;
  background-color: #f4f4f4;
}

/************
 PRIVACY POLICY
***********/
.privacy-1 {
  padding: 80px 0px 0px;
  margin-bottom: 100px;
}

/************
 YOUTUBE VIDEO
***********/
.youtube-embed-1 {
  padding: 80px 0px 100px;
  background-color: #f4f4f4;
}
.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

/************
 CUSTOM TABS
***********/
.custom-tabs.nav-pills .nav-link.active {
  background-color: #fa970d;
}
.custom-tabs.nav-pills .nav-link {
  color: #000;
  cursor: pointer;
}

/************
PAGE SPINNER
*************/
.load-spinner {
  text-align: center;
  /* background-color: rgba(250, 151, 13, 1); */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  /* opacity: 0.5; */
}
